<h2 class="text-3xl md:text-4xl font-semibold mt-9">Skills</h2>

<div class="flex items-center gap-3 mt-3 mb-3">
  <h3 class="font-semibold -ml-2 pl-2">Design</h3>
  <div class="flex-grow w-full h-[1px] bg-[#b0b9c3]" />
</div>
<ul class="list-disc ml-4">
  <li>20+ years in UI/UX, graphic design</li>
  <li>Power user of Ai, Id, Ps, Xd, Figma, Sketch</li>
  <li>Can design in real-time in front of any size group</li>
  <li>Constantly seeking ways to make design invisible</li>
  <li>Fluent in motion design and micro-interactions</li>
  <li>Rapid iteration champion; Designs-in-code</li>
  <li>A seasoned veteran of responsive design</li>
  <li>Proficient in working in design systems</li>
  <li>Limitless on-demand creativity</li>
  <li class="italic">“An Engineer’s Designer”</li>
</ul>

<div class="flex items-center gap-3 mt-4 mb-3">
  <h3 class="font-semibold -ml-3 pl-3">Develop</h3>
  <div class="flex-grow w-full h-[1px] bg-[#b0b9c3]" />
</div>
<ul class="list-disc ml-4">
  <li>20+ years in PHP, Python, HTML, CSS, JavaScript</li>
  <li>Exceptional in Django, Node, React, Svelte, Tailwind</li>
  <li>Pixel-perfect CSS (can match mock-ups perfectly)</li>
  <li>Solid Relational and NoSQL database experience</li>
  <li>Relentless Automator but doesn’t over-abstract</li>
  <li>Versatile in PWA and SPA development stacks</li>
  <li>Solid foundation in Git and its advanced uses</li>
  <li>Skilled in building REST and GraphQL APIs</li>
  <li>Responsible for longevity and maintenance</li>
  <li>Ardent supporter of reactive programming</li>
  <li>Loves to pair up; mentor-it-forward</li>
  <li>Proactive ‘Yes, and’ collaborator</li>
</ul>

<div class="flex items-center gap-3 mt-4 mb-3">
  <h3 class="font-semibold -ml-3 pl-3">Systems</h3>
  <div class="flex-grow w-full h-[1px] bg-[#b0b9c3]" />
</div>
<ul class="list-disc ml-4">
  <li>20+ years in UNIX system administration</li>
  <li>Ubuntu, Gentoo (Stage 0), FreeBSD, OpenBSD</li>
  <li>Graffana, Splunk, Nagios, New Relic, CloudWatch</li>
  <li>Competent in Docker, Kubernetes, Terraform, LXC</li>
  <li>Experienced in hardware forensics and data recovery</li>
  <li>Thinks deeply about Serverless/Infraless architectures</li>
  <li>Effective DNS experience using BIND, djbdns, squid</li>
  <li>Administrator of Sendgrid, Sendmail, qmail, postfix</li>
  <li>Circuit soldering and hardware troubleshooting</li>
  <li>Shell scripting power user (bash, csh, zsh)</li>
</ul>
